import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

const NotFound = () => (
  <div className="content-wrapper-column">
    <div className="page-header">
      <Helmet>
        <title>Page not found | Pannal Village Hall</title>
      </Helmet>
      <h1>Page not found</h1>
      <p>
        <Link to="/">Head home</Link>
      </p>
    </div>
  </div>
);

export default NotFound;
